import { parse } from "cookie";

export function parseCookie(cookie, name) {
  return parse(cookie)[name];
}

export function capitalizeWord(word) {
  let result = '';

  if (!word) return '';

  const splittedWord = word.split(' ');

  splittedWord.forEach((w) => {
    result += capitalizeFirstLetter(w);

    if (result.length < word.length) result += ' ';
  })

  return result;
}

export function capitalizeFirstLetter(str) {
  let result = '';

  if (str) {
    result = str.charAt(0).toUpperCase() + str.slice(1);
  }

  return result;
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function extractQueryParamsToArray(query) {
  const blackListQuery = []
  const observedQuery = []
  Object.keys(query).forEach(key => {
    if (query[key] && !blackListQuery.includes(key)) {
      const value = query[key]?.toString()?.split(',') || [];

      observedQuery.push({
        rawKey: key,
        val: value.map(e => e),
      });
    }
  })

  return observedQuery;
}
