
import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d0b2a8c8a9584bcca914d7287cae7a35@newsentry.pmberjaya.com//2',
    environment: 'next-client-production',
    tracesSampleRate: 1.0,
  });
}
