import posthogjs from 'posthog-js';
import { useEffect, useState, createContext, Dispatch, SetStateAction, FC, useContext } from 'react';
import { isBrowser } from '.'

const PostHostContext = createContext<[typeof posthogjs | null, Dispatch<SetStateAction<typeof posthogjs | null>>]>([null, () => { }]);

export const PostHogProvider: FC = ({ children }: any) => {
	const [posthog, setPosthog] = useState<typeof posthogjs | null>(null)

	useEffect(() => {
		if (isBrowser() && !posthog && process.env.NODE_ENV === 'production') {
			posthogjs.init(
				process.env.POSTHOG_API_KEY || '',
				{
					api_host: process.env.POSTHOG_API_URL,
					loaded: (posthog) => {
						setPosthog(posthog)
					},
					autocapture: false,
					capture_pageview: false,
					disable_session_recording: true,
				})
		}
	}, []);

	return (
		<PostHostContext.Provider value={[posthog, setPosthog]}>
			{children}
		</PostHostContext.Provider>
	)
}

const usePosthog = () => {
	const [posthog] = useContext(PostHostContext)

	function identify(id = "", { email = "" }) {
		if (posthog) {
			posthog.identify(id, { email })
		}
	}

	function getDistinctId() {
		if (posthog) return posthog.get_distinct_id();

		return null;
	}

	function reset() {
		posthog?.reset(true);
	}

	function captureJobSearch({ keyword = '' }) {
		if (posthog) {
			posthog.capture("job_search", { keyword });
		}
	}

	function captureJobDetail({ jobId = '', jobTitle = '' }) {
		if (posthog) {
			posthog.capture("job_detail_view", { job_id: jobId, job_title: jobTitle });
		}
	}

	function captureCompanyDetail({ companyId = '' }) {
		if (posthog) {
			posthog.capture("company_detail_view", { company_id: companyId });
		}
	}

	function captureJobApply({ jobId = '', companyId = '' }) {
		if (posthog) {
			posthog.capture("job_apply", { job_id: jobId, company_id: companyId });
		}
	}

	return {
		captureCompanyDetail,
		captureJobSearch,
		captureJobDetail,
		captureJobApply,
		identify,
		getDistinctId,
		reset,
	};
}

export default usePosthog;
