
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import "../styles/globals.scss";
import NextApp from "next/app";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import Cookies from "universal-cookie";
// import setLanguage from "next-translate/setLanguage";
import useTranslation from "next-translate/useTranslation";
import md5 from "md5";
import { PostHogProvider } from '../utils/posthog'

// import { parseCookie } from "../utils";
// import { serialize } from "cookie";
 
const StateProvider = dynamic(() => import("../context"));

function MyApp({ Component, pageProps }) {
	const { lang } = useTranslation();
	const cookies = new Cookies();
	useEffect(() => {
		if (lang !== "id") {
			cookies.set("language", "id");
		}
	}, [lang]);

	// useEffect(() => {
	//   if (cookies.get("language") !== lang) {
	//     setLanguage(cookies.get("language") || 'id');
	//   }
	// }, [cookies.get("language")]);

	useEffect(() => {
		if (!cookies.get("KITAKERJA_CLIENT_ID") && pageProps?.clientId) {
			cookies.set("KITAKERJA_CLIENT_ID", pageProps?.clientId);
		} else if (!cookies.get("KITAKERJA_CLIENT_ID") && !pageProps?.clientId) {
			cookies.set("KITAKERJA_CLIENT_ID", `${md5(`${new Date().valueOf()}`)}`);
		}
	}, [cookies.get("KITAKERJA_CLIENT_ID")]);

	return (
		<PostHogProvider>
			<StateProvider {...pageProps}>
				<Component {...pageProps} /> 
			</StateProvider>
		</PostHogProvider>
	);
}

const __Page_Next_Translate__ = MyApp;

MyApp.getInitialProps = async (appContext) => {
	const appProps = await NextApp.getInitialProps(appContext);
	return {
		pageProps: {
			...appProps.pageProps,
		},
	};
};


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  